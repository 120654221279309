import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import '../styles/Register.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      await Swal.fire({
        title: 'Success!',
        text: 'Password reset email sent. Check your inbox!',
        icon: 'success',
        confirmButtonText: 'Okay'
      });
      setEmail('');
      navigate('/login');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      await Swal.fire({
        title: 'Error!',
        text: 'Failed to send email: ' + (error.message || 'An error occurred'),
        icon: 'error',
        confirmButtonText: 'Okay'
      });
    }
  };

  return (
    <div className="gradient-bg d-flex justify-content-center align-items-center vh-100">
      <div className="container">
        <div className="text-center mb-4">
          <img
            src={process.env.PUBLIC_URL + '/password.png'}
            alt='Reset Password'
            style={{ width: '100px', height: '100px' }}
          />
        </div>
        <h2 className="text-center text-white mb-4">Forgot Password</h2>
        
        <form onSubmit={handleForgotPassword} className="p-4 rounded shadow" style={{ background: '#444' }}>
          <div className="mb-3">
            <label className="form-label text-white">Email</label>
            <input
              type="email"
              className="form-control custom-placeholder"
              placeholder="your-email@xyz.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ background: '#333', color: 'white', borderColor: '#333' }}
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Send Reset Link
          </button>
        </form>

        <div className="text-center mt-4" style={{ marginBottom: '40px' }}>
          <span className="text-white">Remembered your password?</span>
          <a href="/login" className="text-primary ms-1">Login here</a>
          <br />
          <span className="text-white">Don't have an account?</span>
          <a href="/register" className="text-primary ms-1">Register</a>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
