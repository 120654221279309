// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';

import Earn from './pages/Earn';
import Referrals from './pages/Referalls';
import Profile from './pages/Profile';
import ForgotPassword from './pages/Forgot_password';
import UpdateProfile from './pages/UpdateProfile';
import MainPage from './pages/MainPage';
import Game from './pages/Game';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/earn" element={<Earn />} />
        <Route path="/battlefield" element={<Game />} />
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/updateprofile" element={<UpdateProfile />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    </Router>
  );
}

export default App;
