import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaUser, FaUsers, FaChevronRight, FaCopy } from 'react-icons/fa'; 
import { GiTwoCoins } from "react-icons/gi";
import { endpoints } from '../apiConfig';
import Swal from 'sweetalert2';
import Loader from '../components/Loader';
import { GiBattleGear } from "react-icons/gi";

function Referrals() {
  const [dataloading, setDataLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setDataLoading(true);
      if (user) {
        try {
          const response = await axios.get(`${endpoints.getUserData}/${user.uid}`);
          setUserData(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        navigate('/login');
      }
      setDataLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchReferrals = async () => {
      if (userData?.referral_code) {
        try {
          const response = await axios.get(`${endpoints.referrals}/${userData.referral_code}`);
          setReferrals(response.data);
        } catch (error) {
          console.error('Error fetching referrals:', error);
        } finally {
          setLoading(false);
        }
      }
    };
  
    fetchReferrals();
  }, [userData]);

  const handleCopyClick = () => {
    if (!userData?.referral_code) {
      Swal.fire({
        title: 'Error!',
        text: 'Referral code is not available.',
        icon: 'error',
        confirmButtonText: 'Okay'
      });
      return;
    }

    const referralLink = `${endpoints.refercode}${userData.referral_code}`;

    navigator.clipboard.writeText(referralLink).then(() => {
      Swal.fire({
        title: 'Copied!',
        text: 'Referral link copied to clipboard.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });
    }).catch((err) => {
      console.error('Failed to copy: ', err);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to copy the referral link.',
        icon: 'error',
        confirmButtonText: 'Okay'
      });
    });
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

 
  if (dataloading) {
    return <Loader />; // Or a spinner/loading indicator
  }

  return (
    <div className="gradient-bg d-flex flex-column justify-content-between vh-100">
      <div className="container-fluid gradient-bg">
        <h3 
          className="text-center  mt-2" 
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: '800',
            padding: '15px 25px',
            borderRadius: '12px',
            textShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)',
            letterSpacing: '4px',
            fontSize: '28px',
            lineHeight: '1.1',
            margin: '0 auto',
            width: 'fit-content',
          }}
        >
          <span style={{ color: '#ecf2f8' }}>Para</span>
          <span style={{ color: '#ecf2f8' }}>Hash</span>
        </h3>
        
        <div className="row mb-1">
          <div className="col-12 d-flex justify-content-center">
            <img
              src={process.env.PUBLIC_URL + '/network.png'}
              alt="User Icon"
              style={{ height: '100px', marginRight: '1px', marginTop: '10px' }}
            />
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-12 d-flex justify-content-center">
            <h3 className="text-center text-white mt-2">Add Referrals!</h3>
          </div>
          <p className='text-white text-center'>You and your referrals will get bonuses</p>
        </div>

        <div className="row no-gutters border-top-gold rounded-top w-100 mx-0 shadow-top justify-content-between mb-3">
          <div className="col-12">
            
            <div className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" style={{ borderRadius: '10px' }}>
              <div className="d-flex align-items-center">
                <img
                  src={process.env.PUBLIC_URL + '/refer.png'}
                  alt='refer'
                  style={{ height: '30px', marginRight: '10px' }}
                />
                <div>
                  <span className="text-white">Invite a referral</span>
                  <div className="d-flex align-items-center">
                    <img
                      src={process.env.PUBLIC_URL + '/gold.png'}
                      alt='Refer and Earn'
                      style={{ height: '15px', marginRight: '5px' }}
                    />
                    <span className="text-light" style={{ fontSize: '12px' }}>+20,000 per referral</span>
                  </div>
                </div>
              </div>
              {/* <FaChevronRight className="text-light" /> */}
            </div>

            <div className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" style={{ borderRadius: '10px' }}>
              <div className="d-flex align-items-center">
                <img
                  src={process.env.PUBLIC_URL + '/refer.png'}
                  alt='refer'
                  style={{ height: '30px', marginRight: '10px' }}
                />
                <div>
                  <span className="text-white">Referral Link</span>
                  <div className="d-flex align-items-center">
                    <span className="text-light" style={{ fontSize: '12px' }}>
                      {userData?.referral_code ? `${endpoints.refercode}${userData.referral_code}` : 'Loading...'}
                    </span>
                  </div>
                </div>
              </div>
              <FaCopy className="text-light" onClick={handleCopyClick} style={{ cursor: 'pointer' }} />
            </div>

            <div>
              <h6 className="text-light mb-2 mt-3">List of Referrals</h6>
              {loading ? (
                <p className="text-light">Loading referrals...</p>
              ) : (
                referrals.length > 0 ? (
                  referrals.map(item => (
                    <div
                      key={item.email}
                      className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2"
                      style={{ borderRadius: '10px' }}
                    >
                      <div className="d-flex align-items-center">
                        <FaUser style={{ color: '#ffbf00', marginRight: '10px' }} size={20} />
                        <div>
                          <span className="text-white">{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</span>
                          <div className="d-flex align-items-center">
                            <span className="text-light" style={{ fontSize: '12px' }}>{item.email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-light">No referrals found.</p>
                )
              )}
            </div>
          </div>
        </div>
      </div>

    
      <div className="sticky-bottom d-flex justify-content-around align-items-center bg-dark p-2" style={{ zIndex: 1000 }}>
        <div className="text-center" onClick={() => handleNavigate('/dashboard')}>
          <FaHome style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Home</p> 
        </div>
        <div className="text-center" onClick={() => handleNavigate('/earn')}>
          <GiTwoCoins style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Earn</p> 
        </div>

        <div className="text-center" onClick={() => handleNavigate('/battlefield')}>
          <GiBattleGear style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Battlefield</p> 
        </div>


        <div className="text-center" onClick={() => handleNavigate('/referrals')}>
          <FaUsers style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Referrals</p> 
        </div>
        <div className="text-center" onClick={() => handleNavigate('/profile')}>
          <FaUser style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Profile</p> 
        </div>
      </div>






    </div>
  );
}

export default Referrals;
