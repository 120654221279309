import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/Register.css'; // Import any specific styles if needed
import { FaEnvelope, FaLock } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Loader from '../components/Loader';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/dashboard');
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
        await signInWithEmailAndPassword(auth, email, password);
        setTimeout(() => {
            navigate('/dashboard');
        }, 1000);
    } catch (error) {
        console.error('Error logging in:', error);

        let errorMessage = 'An error occurred. Please try again.';
        switch (error.code) {
            case 'auth/user-not-found':
                errorMessage = 'No user found with this email address.';
                break;
            case 'auth/wrong-password':
                errorMessage = 'Invalid password. Please check your password and try again.';
                break;
            case 'auth/invalid-credential':
                errorMessage = 'Invalid email or password. Please check your credentials and try again.';
                break;
            // You can handle other error cases if needed
            default:
                errorMessage = error.message || 'An unexpected error occurred.';
                break;
        }

        await Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Okay'
        });
    } finally {
        setLoading(false);
    }
};

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="gradient-bg d-flex justify-content-center align-items-center vh-100">
      <div className="container">
        <div className="mb-4 text-center">
          <img 
            src={process.env.PUBLIC_URL + '/click.png'} 
            alt="Logo" 
            style={{ width: '100px', marginBottom: '10px' }} 
          />
          <h2 className="text-white">Log in to your account</h2>
        </div>

        <form onSubmit={handleLogin} className="p-4 rounded shadow" style={{ background: '#444' }}>
          <div className="mb-3">
            <label className="form-label text-white">Email</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaEnvelope />
              </span>
              <input
                type="email"
                className="form-control custom-placeholder"
                placeholder="your-email@xyz.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333', outline: 'none' }}
              />
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label text-white">Password</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaLock />
              </span>
              <input
                type="password"
                className="form-control custom-placeholder"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Login
          </button>

          <div className="text-center mt-4" style={{ marginBottom: '40px' }}>
            <a href="/forgot-password" className="text-white me-3">Forgot Password?</a>
            <span className="text-white">|</span>
            <a href="/register" className="text-white ms-3">Register</a>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
