// // In src/firebaseConfig.js
// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "your_api_key",
//   authDomain: "your_auth_domain",
//   projectId: "your_project_id",
//   storageBucket: "your_storage_bucket",
//   messagingSenderId: "your_messaging_sender_id",
//   appId: "your_app_id"
// };

// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);




// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEr2FUqSvFufzaeYtFGdgGbb1G1_Hc2uA",
  authDomain: "parahash-e05c2.firebaseapp.com",
  projectId: "parahash-e05c2",
  storageBucket: "parahash-e05c2.appspot.com",
  messagingSenderId: "129182656705",
  appId: "1:129182656705:web:cb83f24fb926fd9043cc40",
  measurementId: "G-8F1CNJJLDC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);