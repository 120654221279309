import React from 'react';
import '../styles/Game.css';

import { useNavigate } from 'react-router-dom';
import { FaHome, FaUser, FaUsers } from 'react-icons/fa';
import { GiTwoCoins, GiBattleGear } from "react-icons/gi";

const Game = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="game-background">
      <div className="game-content">
        <h1 className="game-title">Battlefield</h1>
        <p className="game-subtitle">Coming Soon</p>
      </div>

      <div className="bottom-menu">
        <div className="menu-item" onClick={() => handleNavigation('/dashboard')}>
          <FaHome style={{ color: '#ffbf00' }} size={16} />
          <p>Home</p>
        </div>
        <div className="menu-item" onClick={() => handleNavigation('/earn')}>
          <GiTwoCoins style={{ color: '#ffbf00' }} size={16} />
          <p>Earn</p>
        </div>
        <div className="menu-item" onClick={() => handleNavigation('/battlefield')}>
          <GiBattleGear style={{ color: '#ffbf00' }} size={16} />
          <p>Battlefield</p>
        </div>
        <div className="menu-item" onClick={() => handleNavigation('/referrals')}>
          <FaUsers style={{ color: '#ffbf00' }} size={16} />
          <p>Referrals</p>
        </div>
        <div className="menu-item" onClick={() => handleNavigation('/profile')}>
          <FaUser style={{ color: '#ffbf00' }} size={16} />
          <p>Profile</p>
        </div>
      </div>
    </div>
  );
};

export default Game;
