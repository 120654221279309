// src/pages/Dashboard.js
import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FaHome,FaCheckCircle,FaUser, FaPhone, FaUsers, FaEnvelope, FaWallet, FaSignOutAlt } from 'react-icons/fa'; // Importing icons from react-icons
import { GiTwoCoins } from "react-icons/gi";
import { ImProfile } from "react-icons/im";
import { endpoints } from '../apiConfig';
import { FaChevronRight } from 'react-icons/fa';
import Loader from '../components/Loader';
import { GiBattleGear } from "react-icons/gi";


function Profile() {
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        try {
          const response = await axios.get(`${endpoints.getUserData}/${user.uid}`);
          setUserData(response.data); // Set user data from response

       
          
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        console.log('No user is logged in'); // Debugging step
        navigate('/login');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);
  

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };


  const handleBattlefield = () => {
    navigate('/battlefield');
  };
  

  const handleUpdateProfile = () => {
    navigate('/updateprofile');
  };

  const handleEarn = async () => {
    
    navigate('/earn');
  };

  const handleReferrals = async () => {
    
    navigate('/referrals');
  };

  const handleProfile = async () => {
    
    navigate('/profile');
  };

  const handleHome = async () => {
    
    navigate('/dashboard');
  };


  if (loading) {
    return <Loader />; // Or a spinner/loading indicator
  }


  return (
   
    <div className="gradient-bg d-flex flex-column justify-content-between vh-100">
      <div className="container-fluid gradient-bg">
      <h3 
  className="text-center mb-4 mt-2" 
  style={{
    fontFamily: "'Montserrat', sans-serif",  // Modern, bold font
    fontWeight: '800',
    padding: '15px 25px',
    borderRadius: '12px',
    textShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)',  // Strong, prominent shadow
    letterSpacing: '4px',  // Wide spacing for modern look
    fontSize: '28px',  // Larger size for mobile impact
    lineHeight: '1.1',  // Tight line height for compactness
    margin: '0 auto',  // Center the text container
    width: 'fit-content',  // Adjust width to content size
  }}
>
  <span style={{ color: '#ecf2f8' }}>Para</span>
  <span style={{ color: '#ecf2f8' }}>Hash</span>
</h3>
        
        
        
        <div className="row mb-1">
      <div className="col-12 d-flex justify-content-center">
        <img
          src={process.env.PUBLIC_URL + '/profilehash.png'} // Replace with your image path
          alt="User Icon"
          className="" // Add a class for rounded shadow styling
          style={{ height: '50px', marginRight: '1px', marginTop: '10px' }} // Adjust size and margin as needed
        />
       
      </div>
    </div>


    <div className="row mb-1">
      <div className="col-12 d-flex justify-content-center">
      <h3 className="text-center text-white  mt-2">My Profile</h3>
     
      </div>
      <p className='text-white text-center'>Profile Details</p>
    </div>



    <div className="row no-gutters border-top-gold rounded-top w-100 mx-0 shadow-top justify-content-between mb-3">
      <div className="col-12">

      {userData && (
  <div>
    {/* Name Field */}
    <div className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" style={{ borderRadius: '10px' }}>
      <div className="d-flex align-items-center">
        <FaUser style={{ color: '#ffbf00', marginRight: '10px' }} size={16} /> {/* Reduced icon size */}
        <div>
          <span className="text-white">{userData.name.charAt(0).toUpperCase() + userData.name.slice(1)}</span> {/* Capitalize the first letter */}
          <div className="text-light" style={{ fontSize: '12px' }}>Name</div> {/* Subtitle */}
        </div>
      </div>
      <FaCheckCircle className="text-light" />
    </div>

    {/* Phone Field */}
    <div className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" style={{ borderRadius: '10px' }}>
      <div className="d-flex align-items-center">
        <FaPhone style={{ color: '#ffbf00', marginRight: '10px' }} size={16} /> {/* Reduced icon size */}
        <div>
          <span className="text-white">{userData.phone}</span>
          <div className="text-light" style={{ fontSize: '12px' }}>Phone Number</div> {/* Subtitle */}
        </div>
      </div>
      <FaCheckCircle className="text-light" />
    </div>

    {/* Email Field */}
    <div className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" style={{ borderRadius: '10px' }}>
      <div className="d-flex align-items-center">
        <FaEnvelope style={{ color: '#ffbf00', marginRight: '10px' }} size={16} /> {/* Reduced icon size */}
        <div>
          <span className="text-white">{userData.email}</span>
          <div className="text-light" style={{ fontSize: '12px' }}>Email Address</div> {/* Subtitle */}
        </div>
      </div>
      <FaCheckCircle className="text-light" />
    </div>

    {/* Wallet Field */}
    <div className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" style={{ borderRadius: '10px' }}>
  <div className="d-flex align-items-center">
    <FaWallet style={{ color: '#ffbf00', marginRight: '10px' }} size={16} /> {/* Reduced icon size */}
    <div>
      <span className="text-white">{userData.wallet || 0}</span> {/* Set to 0 if data is not available */}
      <div className="text-light" style={{ fontSize: '12px' }}>Wallet Address (BEP20)</div> {/* Subtitle */}
    </div>
  </div>
  <FaCheckCircle className="text-light" />
</div>


<div className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" style={{ borderRadius: '10px' }}>
  <div className="d-flex align-items-center">
    <FaUser style={{ color: '#ffbf00', marginRight: '10px' }} size={16} /> {/* Reduced icon size */}
    <div>
      <span className="text-white">{userData.referral_code || 0}</span> {/* Set to 0 if data is not available */}
      <div className="text-light" style={{ fontSize: '12px' }}>Referral Code</div> {/* Subtitle */}
    </div>
  </div>
  <FaCheckCircle className="text-light" />
</div>



<div 
  className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" 
  style={{ borderRadius: '10px' }} 
  onClick={handleUpdateProfile} // Update onClick to handle navigation to UpdateProfile
>
  <div className="d-flex align-items-center">
    <ImProfile style={{ color: '#ffbf00', marginRight: '10px' }} size={16} /> {/* Reduced icon size */}
    <div>
      <span className="text-white">Update Profile</span>
    </div>
  </div>
  <FaChevronRight className="text-light" />
</div>




<div className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2" style={{ borderRadius: '10px' }} onClick={handleLogout}>
  <div className="d-flex align-items-center">
    <FaSignOutAlt style={{ color: '#ffbf00', marginRight: '10px' }} size={16} /> {/* Reduced icon size */}
    <div>
      <span className="text-white">Log Out</span> {/* Set to 0 if data is not available */}
      
    </div>
  </div>
  
</div>




  </div>
)}



      </div>




    </div>
















      </div>

     
      <div className="sticky-bottom d-flex justify-content-around align-items-center bg-dark p-2" style={{ zIndex: 1000 }}>
  <div className="text-center" onClick={handleHome}>
    <FaHome style={{ color: '#ffbf00' }} size={16} /> {/* Reduced icon size */}
    <p className="text-white mb-0" style={{ fontSize: '12px' }}>Home</p> {/* Reduced font size */}
  </div>
  <div className="text-center" onClick={handleEarn}>
    <GiTwoCoins style={{ color: '#ffbf00' }} size={16} /> {/* Reduced icon size */}
    <p className="text-white mb-0" style={{ fontSize: '12px' }}>Earn</p> {/* Reduced font size */}
  </div>

  <div className="text-center" onClick={handleBattlefield}>
          <GiBattleGear style={{ color: '#ffbf00' }} size={16} />
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Battlefield</p>
        </div>


  <div className="text-center" onClick={handleReferrals}>
    <FaUsers style={{ color: '#ffbf00' }} size={16} /> {/* Reduced icon size */}
    <p className="text-white mb-0" style={{ fontSize: '12px' }}>Referrals</p> {/* Reduced font size */}
  </div>
  <div className="text-center" onClick={handleProfile}>
    <FaUser style={{ color: '#ffbf00' }} size={16} /> {/* Reduced icon size */}
    <p className="text-white mb-0" style={{ fontSize: '12px' }}>Profile</p> {/* Reduced font size */}
  </div>
</div>





    </div>

  );
}

export default Profile;


