import React from 'react';
import '../styles/MainPage.css'; // Custom CSS for additional styling
import { FaRocket, FaPuzzlePiece, FaUsers, FaGlobe, FaTwitter, FaFacebook, FaYoutube } from 'react-icons/fa'; // Importing icons

const MainPage = () => {
  return (
    <>
      <section id="hero" className="hero bg-dark text-white d-flex align-items-center vh-100">
        <div className="container text-center position-relative">
          <div className="row justify-content-center" data-aos="fade-in">
            <div className="col-lg-8">
              <img
                src={process.env.PUBLIC_URL + '/click.png'}
                alt="ParaHash Logo"
                className="mb-4"
                style={{ width: '120px', height: 'auto' }} // Adjusted logo size
              />
              <h1 className="display-4 mb-3 gradient-text">ParaHash</h1>
              <h2 className="lead mb-4 gradient-text-secondary">Gaming Innovation</h2>
              <p className="mb-5 gradient-text-para" style={{ fontSize: '1.2rem', lineHeight: '1.5' }}>
              Connect, Collaborate, Create: The Future of Decentralized Play!
              </p>
              <div>
                <a href="/login" className="btn btn-primary btn-lg px-5 py-3">Get Started</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="roadmap" className="roadmap py-5">
  <div className="container">
    <h2 className="text-center mb-5 text-white">Our Roadmap</h2>
    <div className="timeline">
      <div className="timeline-item left">
        <div className="timeline-content shadow">
          <h4 className="timeline-title">
            <FaRocket color="#FF5733" size="1.5em" className="mr-2" /> 
            <span style={{ color: '#FF5733' }}>Phase 1: Airdrop</span>
          </h4>
          <p style={{ color: '#ffffff' }}>
            Launch our initial airdrop campaign to distribute tokens to early adopters and gamers. 
            This phase aims to create awareness and excitement about the ParaHash platform, incentivizing users to join our community and start their journey with us.
          </p>
        </div>
      </div>
      <div className="timeline-item right">
        <div className="timeline-content shadow">
          <h4 className="timeline-title">
            <FaPuzzlePiece color="#FFC300" size="1.5em" className="mr-2" /> 
            <span style={{ color: '#FFC300' }}>Phase 2: Community Building</span>
          </h4>
          <p style={{ color: '#ffffff' }}>
            Focus on gathering a vibrant community of gamers and crypto enthusiasts. 
            We will host events, webinars, and social media campaigns to engage users, gather feedback, and build relationships. 
            Our goal is to create an inclusive environment where everyone can contribute to the future of ParaHash.
          </p>
        </div>
      </div>
      <div className="timeline-item left">
        <div className="timeline-content shadow">
          <h4 className="timeline-title">
            <FaUsers color="#DAF7A6" size="1.5em" className="mr-2" /> 
            <span style={{ color: '#DAF7A6' }}>Phase 3: Gaming Development</span>
          </h4>
          <p style={{ color: '#ffffff' }}>
            Begin the development of engaging and innovative gaming experiences on the ParaHash platform. 
            This phase will focus on building unique games that leverage blockchain technology, 
            offering users a seamless and decentralized gaming experience while rewarding them for their participation.
          </p>
        </div>
      </div>
      <div className="timeline-item right">
        <div className="timeline-content shadow">
          <h4 className="timeline-title">
            <FaGlobe color="#33FFBD" size="1.5em" className="mr-2" /> 
            <span style={{ color: '#33FFBD' }}>Phase 4: Continuous Development</span>
          </h4>
          <p style={{ color: '#ffffff' }}>
            Establish a routine of regular updates and new features to enhance the gaming platform continuously. 
            We will actively listen to user feedback and implement improvements that add real value, ensuring a dynamic and evolving gaming ecosystem that keeps our users engaged and satisfied.
          </p>
        </div>
      </div>
    </div>
  </div>

        <footer className="footer bg-dark text-white py-4">
        <div className="container text-center">
          <p>Follow Us On</p>
          <div className="social-icons">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter color="#1DA1F2" size="2em" className="mx-2" />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook color="#4267B2" size="2em" className="mx-2" />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <FaYoutube color="#FF0000" size="2em" className="mx-2" />
            </a>
          </div>
        </div>
      </footer>

      </section>

     

      <style jsx>{`
        .gradient-text {
          background: linear-gradient(90deg, #ff7e5f, #feb47b);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
        }

        .gradient-text-secondary {
          background: linear-gradient(90deg, #43cea2, #185a9d);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
        }

        .gradient-text-para {
          background: linear-gradient(90deg, #00c6ff, #0072ff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .hero, .roadmap, .footer {
          background: linear-gradient(135deg, #0a0a0a 0%, #141414 100%);
          box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
        }

        .btn-primary {
          background-color: #007bff;
          border: none;
          transition: background-color 0.3s ease;
        }

        .btn-primary:hover {
          background-color: #0056b3;
          transform: translateY(-2px);
        }

        .timeline {
          position: relative;
          max-width: 1200px;
          margin: 0 auto;
          padding: 0;
        }

        .timeline-item {
          padding: 20px 40px;
          position: relative;
          background-color: inherit;
          width: 50%;
        }

        .timeline-item.left {
          left: 0;
        }

        .timeline-item.right {
          left: 50%;
        }

        .timeline-content {
          padding: 20px;
          background-color: #1a1a1a;
          position: relative;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        }

        .timeline-title {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          font-weight: bold;
        }

        .timeline-title span {
          margin-left: 10px;
          font-size: 1.2rem;
        }

        .timeline-content p {
          margin: 0;
          color: #ffffff;
        }

        .footer {
          margin-top: 50px;
        }

        .social-icons a {
          color: white;
          transition: transform 0.3s ease;
        }

        .social-icons a:hover {
          transform: translateY(-5px);
        }

        @media (max-width: 768px) {
          .hero, .roadmap {
            padding: 60px 0;
          }

          .timeline-item {
            width: 100%;
            left: 0 !important;
            margin-bottom: 20px;
          }

          .timeline-item.right::before, .timeline-item.left::before {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default MainPage;
